import Vue from 'vue'
import * as types from '../mutation-types'

export default {
	[types.SET_AUTO_THEME_SELECTION]: (state, autoThemeSelection) => {
		Vue.set(state, 'autoThemeSelection', autoThemeSelection)
	},
	[types.SET_AUTO_CASE_SELECTION]: (state, autoCaseSelection) => {
		Vue.set(state, 'autoCaseSelection', autoCaseSelection)
	},

	[types.SET_AUTO_ROOM_CODE]: (state, autoRoomCode) => {
		Vue.set(state, 'autoRoomCode', autoRoomCode)
	},
}

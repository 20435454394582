// errors
export default {
	getErrorMessage(state) {
		return state.errorMessage
	},

	getErrorIcon(state) {
		return state.errorIcon
	},

	getErrorButton(state) {
		return state.errorButton
	},

	getErrorButtonAction(state) {
		return state.errorButtonAction
	},
}

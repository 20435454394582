export default {
    mainMenu: {
        join: 'Start',
        create: 'Start',
        admin: 'Admin',
        language: 'Jazyk',
    },
    login: {
        username: 'U\u017eivatelsk\u00e9 jm\u00e9no',
        password: 'Heslo',
        login: 'P\u0159ihl\u00e1sit se',
        failed: 'P\u0159ihl\u00e1\u0161en\u00ed selhalo',
        logout: 'Odhl\u00e1sit se',
    },
    intro: {
        cultureHeadline: 'Na\u0161e kultura Chr.Hansen',
        cultureParagraph: '\'Spole\u010dn\u00e9 porozum\u011bn\u00ed a artikulace na\u0161\u00ed kultury n\u00e1m umo\u017en\u00ed mluvit spolu JEDN\u00cdM hlasem a nahl\u00ed\u017eet vp\u0159ed - do budoucnosti a p\u0159itom z\u016fstat v\u011brni hodnot\u00e1m, d\u00edky kter\u00fdm jsme se sem dostali.\' \u2013 Mauricio Graber, CEO',

        whyUseHeadline: 'Pro\u010d pr\u00e1v\u011b tento n\u00e1stroj dialogu o kultu\u0159e?',
        whyUseParagraph1: 'Jako spole\u010dnost rosteme a ka\u017ed\u00fd den \u010del\u00edme nov\u00fdm p\u0159\u00edle\u017eitostem a v\u00fdzv\u00e1m. Nyn\u00ed je mnohem d\u016fle\u017eit\u011bj\u0161\u00ed ne\u017e kdy jindy, abychom vedli dialogy o tom, jak se na\u0161e kultura Chr. Hansen p\u0159etavuje do na\u0161\u00ed ka\u017edodenn\u00ed pr\u00e1ce a jak n\u00e1m m\u016f\u017ee b\u00fdt n\u00e1pomocn\u00e1 p\u0159i p\u0159ij\u00edm\u00e1n\u00ed spr\u00e1vn\u00fdch rozhodnut\u00ed.',
        whyUseParagraph2: '\u00da\u010delem tohoto n\u00e1stroje kulturn\u00edho dialogu je v\u00e9st tento dialog poutav\u00fdm zp\u016fsobem.',

        whenToUse: '# Kdy pou\u017e\u00edt dialogov\u00fd n\u00e1stroj Chr. Hansena?\n' +
            '\n' +
            'N\u00e1stroj m\u016f\u017eete pou\u017e\u00edvat v n\u00e1sleduj\u00edc\u00edch t\u0159ech situac\u00edch:\n' +
            '\n' +
            '1. Rozvoj t\u00fdmu\n' +
            '2. Onboarding (P\u0159ijet\u00ed)\n' +
            '3. St\u0159etnut\u00ed t\u00fdmu (kr\u00e1tk\u00e1 nebo dlouh\u00e1 verze)\n' +
            '\n' +
            'D\u00e9lka ka\u017ed\u00e9ho sezen\u00ed se bude li\u0161it v z\u00e1vislosti na po\u010dtu \u00fa\u010dastn\u00edk\u016f a hloubce diskus\u00ed. \u00dakolem facilit\u00e1tora je \u00fa\u010dastn\u00edky v\u00e9st a p\u0159ipravit sc\u00e9nu pro ka\u017ed\u00e9 sezen\u00ed.\n' +
            '\n' +
            'Kliknut\u00edm na **Dal\u0161\u00ed** vyberte nastaven\u00ed pro konkr\u00e9tn\u00ed sezen\u00ed.',

        title: 'V\u00edtejte v na\u0161em\n' +
            'n\u00e1stroji kulturn\u00edho\n' +
            'dialogu Chr.Hansen',
        description: 'intro.description',
        instruction: 'V\u00edtejte na sezen\u00ed T\u00edmov\u00fd dialog. A\u017e budete v\u0161ichni p\u0159ihl\u00e1\u0161eni a p\u0159ipraveni, pokra\u010dujte kliknut\u00edm na \'Dal\u0161\u00ed\'.',
    },
    room: {
        connecting: 'P\u0159ipojuje se',
        loading: 'Na\u010d\u00edt\u00e1v\u00e1 se',
        roomCode: 'K\u00f3d m\u00edstnosti',
        participate: '\u00da\u010dastnit se',
        enterDescription: 'Zadejte k\u00f3d z obrazovky facilit\u00e1tora',
        enter: 'Vstoupit',
        description: 'Naskenujte QR k\u00f3d nebo klikn\u011bte na odkaz.<br>Pot\u00e9 zadejte n\u00e1sleduj\u00edc\u00ed k\u00f3d:',
        join: 'P\u0159ipojit se',
        url: 'Url',
        codeError: 'Nespr\u00e1vn\u00fd k\u00f3d m\u00edstnosti',
        serverError: '\u017d\u00e1dn\u00e9 p\u0159ipojen\u00ed k serveru',
        connectionError: 'Nelze se p\u0159ipojit',
        lobby: 'Lobby',
        waiting: '\u010cek\u00e1m',
        pageType: 'Hern\u00ed re\u017eim',
        unset: 'Nenastaveno',
        dilemma: 'Hlasovat a diskusovat',
        count: 'Hr\u00e1\u010di se p\u0159idali',
        areReady: 'P\u0159ipraveno',
    },
    master: {
        selectTheme: 'Zvolte t\u00e9mu',
    },
    game: {
        answers: 'Odpov\u011bdi',
        chooseAnswer: 'Vyberte odpov\u011b\u010f',
        showAnswers: 'V\u00fdsledky',
        sendMessage: 'Odeslat zpr\u00e1vu',
        sendMoment: 'Odeslat moment',
        sendDilemma: 'Odeslat dilemu',
        sendAnswer: 'Odeslat odpov\u011b\u010f',
        thankYouParticipation: 'D\u011bkujeme za Va\u0161i \u00fa\u010dast',
        thankYouVote: 'D\u011bkujeme za v\u00e1\u0161 hlas',
        studentWaiting: 'Pod\u00edvat se na obrazovku facilit\u00e1tora',
        stayTuned: 'Z\u016fsta\u0148te s n\u00e1mi!',
        timerSeconds: 'Sek',
        ready: 'P\u0159ipraveno',
        waitingForUserInput: '\u010cek\u00e1n\u00ed na vstup \u00fa\u010dastn\u00edk\u016f...',
        waitingForInput: '\u010cek\u00e1n\u00ed na vstup...',
    },
    instructions: {
        title: 'Instrukce',
        moments: 'instructions.moments',
        dilemmas: 'instructions.dilemmas',
        weightBoard: 'instructions.weightBoard',
        weightBoard2D: 'Kliknut\u00edm na desku um\u00edst\u011bte v\u00e1\u0161 token',
    },
    misc: {
        back: 'Zp\u011bt',
        cancel: 'Zru\u0161it',
        quit: 'Ukon\u010dit',
        start: 'Start',
        startAgainMoment: 'Vyberte Nov\u00fd moment',
        startAgainDilemma: 'Vyberte Nov\u00e9 dilema',
        next: 'Dal\u0161\u00ed',
        finish: 'Dokon\u010dit',
        continue: 'Pokra\u010dovat',
        showResult: 'Zobrazit v\u00fdsledek',
        select: 'Zvolit',
        fullScreen: 'Cel\u00e1 obrazovka',
        writeMomentPlaceholder: 'Klikn\u011bte na pole a napi\u0161te v\u00e1\u0161 moment.',
        writeDilemmaPlaceholder: 'Klikn\u011bte na pole a napi\u0161te va\u0161i dilemu.',
    },
}
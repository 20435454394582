// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
//import "@babel/polyfill";

import router from './router.js'
import Vue from 'vue'
import store from './store/'
import {i18n} from './localization'

import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js'
import '../node_modules/animate.css/animate.min.css'
import '../node_modules/vue2-animate/dist/vue2-animate.min.css'
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'

import App from './App.vue'

import VueSimpleSVG from 'vue-simple-svg'
import NoDragImage from '@/components/client-components/sgi/NoDragImage'

Vue.use(VueSimpleSVG)

Vue.config.productionTip = false

Vue.component('no-drag-image', NoDragImage)

/* eslint-disable no-new */
window.app = new Vue({
	el: '#app',
	router,
	store,
	i18n,
	template: '<App/>',
	components: {
		App,
	},
})

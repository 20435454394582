import Vue from 'vue'
import * as types from '../mutation-types'

export default {
	[types.CLEAR_PAGE_HISTORY]: (state) => {
		Vue.set(state, 'pageHistory', [])
	},
	[types.ADD_CURRENT_PAGE_INDEX]: (state, currentPageIndex) => {
		Vue.set(state, 'currentPageIndex', currentPageIndex)

		let pageHistory = state.pageHistory || []

		if (pageHistory.indexOf(currentPageIndex) === -1) {
			pageHistory.push(currentPageIndex)

			Vue.set(state, 'pageHistory', pageHistory)

			console.log('New page history: ', pageHistory, state)
		} else {
			console.log('Page history already had index ' + currentPageIndex +
				' not readding it!')
		}
	},
	[types.ADD_CURRENT_PAGE_CHOICE]: (state, obj) => {
		let choiceComponent

		let choice = obj.choice
		let side = obj.side

		let choiceCollections = []

		if (side === 'left') {
			//choiceCollections.push(state.currentPage.component_left.options.choices);
			choiceCollections.push(
				state.currentPage.components.left.options.choices)

			choiceCollections.push(
				state.currentCase.pages[state.currentPageIndex].components.left.options.choices)
		} else {
			//choiceCollections.push(state.currentPage.component_right.options.choices);
			choiceCollections.push(
				state.currentPage.components.right.options.choices)

			choiceCollections.push(
				state.currentCase.pages[state.currentPageIndex].components.right.options.choices)
		}

		console.log('ADD_CURRENT_PAGE_CHOICE.state', state, choice)
		console.log('choiceCollections', choiceCollections)

		for (let choiceCollection of choiceCollections) {
			choiceCollection.push(choice)
		}
	},
	[types.SET_CURRENT_PAGE]: (state, currentPage) => {
		Vue.set(state, 'currentPage', currentPage)
	},
	[types.SET_CURRENT_PAGE_INDEX]: (state, currentPageIndex) => {
		Vue.set(state, 'currentPageIndex', currentPageIndex)
	},
	[types.POP_CURRENT_PAGE_INDEX]: (state, amount = 1) => {
		let pageHistory = state.pageHistory || []

		let minPagePop = null

		for (let i = 1; i <= amount; i++) {
			let pagePop = pageHistory.pop()

			if (minPagePop === null || pagePop < minPagePop) {
				minPagePop = pagePop
			}
		}

		Vue.set(state, 'pageHistory', pageHistory, 'poppedIndex', amount)
		console.log('New page history: ', pageHistory, state)
	},
	[types.SET_CURRENT_PAGE_PHASE]: (state, currentPagePhase) => {
		Vue.set(state, 'currentPagePhase', currentPagePhase)
	},
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import code from './languages/code'

import en from './languages/en';
import cs from './languages/cs';
import cn from './languages/cn';
import pt from './languages/pt';
import da from './languages/da';
import de from './languages/de';
import fr from './languages/fr';
import es from './languages/es';

import deepmerge from 'deepmerge'

Vue.use(VueI18n)

const translations = {
    code: code,

    en: deepmerge(code, en), // English
    cs: deepmerge(code, cs), // Czech
    cn: deepmerge(code, cn), // Chinese
    pt: deepmerge(code, pt), // Portuguese
    da: deepmerge(code, da), // Danish
    de: deepmerge(code, de), // German
    fr: deepmerge(code, fr), // French
    es: deepmerge(code, es), // Spanish
}

export const i18n = new VueI18n({
    messages: translations,
    silentTranslationWarn: true, // Disables warning clogging up the console
})

<template>
	<div class="thing-container">
		<div
			ref="thing"
			:style="styleTags"
		>
			<!-- This will automatically be filled with content from thing-to-html -->
		</div>
	</div>
</template>

<script>
import thingToHTML from './thing-to-html/thingToHTML'

export default {
	props: {
		thing: {
			required: true,
		},
		theme: {
			type: String,
			required: false,
			default: 'dark',
		},
		button: {
			type: Boolean,
			required: false,
			default: true,
		},
		background: {
			type: String,
			required: false,
			default: '#272822',
		},
		padding: {
			type: String,
			required: false,
			default: '30px',
		},
	},
	watch: {
		thing(newThing, oldThing) {
			this.thing = newThing

			this.clear()
			this.draw()
		},
	},
	computed: {
		styleTags() {
			return {
				background: this.background,
				padding: this.padding,
			}
		},
	},
	methods: {
		clear() {
			let thing = this.$refs.thing

			while (thing.firstChild) {
				thing.removeChild(thing.firstChild)
			}
		},
		draw() {
			let thingToHTMLOptions = {
				thing: this.thing,
				container: this.$refs.thing,
				button: this.button,
				theme: this.theme,
			}

			this.$refs.thing.css

			thingToHTML(thingToHTMLOptions)
		},
	},
	mounted() {
		this.draw()
	},
}
</script>

<style lang="less">
@import "~@less/font-sizes.less";

.thing-container {
	@import "thing-to-html/thing";

	.thing {
		display: block;
		overflow-y: auto;
		font-size: @app-font-size-thing-to-html-thing;
		padding: 10px;
	}
}
</style>

import Vue from 'vue'
import * as types from '../mutation-types'

export default {
	[types.SET_ERROR_MESSAGE]: (state, errorMessage) => {
		Vue.set(state, 'errorMessage', errorMessage)
	},
	[types.SET_ERROR_ICON]: (state, errorMessage) => {
		Vue.set(state, 'errorIcon', errorMessage)
	},
	[types.SET_ERROR_BUTTON]: (state, errorButton) => {
		Vue.set(state, 'errorButton', errorButton)
	},
	[types.SET_ERROR_BUTTON_ACTION]: (state, errorButtonAction) => {
		Vue.set(state, 'errorButtonAction', errorButtonAction)
	},
	[types.CLEAR_ERROR_MESSAGE]: (state) => {
		Vue.set(state, 'errorMessage', null)
	},
}

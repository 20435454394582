// case

import * as types from '@/store/mutation-types';
import Vue from 'vue';

export default {
	getTimerTimeTotal(state) {
		return state.timerTimeTotal;
	},
	getTimerTimeRemaining(state) {
		return state.timerTimeRemaining;
	},
	getPopupText(state) {
		return state.popupText;
	},
	getPopupHeadline(state) {
		return state.popupHeadline;
	},
	getTeacherPassword(state) {
		return state.teacherPassword
	},
	getTeacherSkipIntro(state) {
		return state.teacherSkipIntro
	},
	getShowBigQrCode(state) {
		return state.showBigQrCode
	},
	getShowHelp(state) {
		return state.showHelp;
	},
	getPlayerType(state) {
		return state.playerType
	},
	getBackgroundAccent(state) {
		return state.backgroundAccent
	},
	getBreadcrumbTo(state) {
		return state.breadcrumbTo
	},
	getCurrentGamePhase(state) {
		return state.currentGamePhase
	},
	getLastEmojiCode(state) {
		return state.lastEmojiCode
	},

	getGameClientCloseRedirectUrl(state) {
		return state.gameClientCloseRedirectUrl
	},

	getNavigationGuardEnabled(state) {
		return state.navigationGuardEnabled
	},

	getGameType(state) {
		return state.gameType
	},

	getGameClientType(state) {
		return state.gameClientType
	},

	getSessionId(state) {
		return state.sessionId
	},

	getShowFooter(state) {
		return state.showFooter
	},

	getLanguage(state) {
		return state.language
	},

	getFeatures(state) {
		return state.features
	},

	getShowHeaderCaseName(state) {
		return state.showHeaderCaseName
	},

	getClientId(state) {
		return state.clientId
	},
}

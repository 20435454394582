function pad(number, length) {
    let str = number.toString();

    while (str.length < length) {
        str = '0' + str;
    }

    return str;
}

export function formatDateYMDHIS(date) {
    let dateParts = {
        year: pad(date.getFullYear(), 4),
        month: pad(date.getMonth() + 1, 2),
        day: pad(date.getDate(), 2),

        hour: pad(date.getHours(), 2),
        minute: pad(date.getMinutes(), 2),
        second: pad(date.getSeconds(), 2),
    };

    return `${dateParts.year}-${dateParts.month}-${dateParts.day} ${dateParts.hour}:${dateParts.minute}:${dateParts.second}`;
}
import Vue from 'vue'
import * as types from '../mutation-types'

export default {
	[types.SET_CURRENT_CASE]: (state, currentCase) => {
		if (currentCase) {
			state.session = {
				caseId: currentCase.id,
				responses: [],
				language: state.language,
			}
		} else {
			state.session = null
		}

		Vue.set(state, 'currentCase', currentCase)
	},
}

// case
export default {
	getPageHistory(state) {
		return state.pageHistory
	},

	getCurrentPage(state) {
		console.log('INSIDE STATE', state.currentPage)
		return state.currentPage
	},

	getCurrentPageIndex(state) {
		console.log('GET CURRENT PAGE INDEX sTILL BEING CALLED???', state)

		return state.currentPageIndex
	},

	getCurrentPagePhase(state) {
		return state.currentPagePhase
	},
}

import PagePhase from '@/enum/PagePhase'
import PlayerType from '@/enum/PlayerType'
import AppConfig from '@/app-config'

let lastEmojiCode = localStorage.getItem('lastEmojiCode')
let lastEmojiCodeTime = localStorage.getItem('lastEmojiCodeTime')

if (lastEmojiCodeTime) {
	const fourHoursInSeconds = 60 * 60 * 4

	let lastEmojiCodeTimeCalc = ((new Date()).getTime() - lastEmojiCodeTime) /
		1000

	if (lastEmojiCodeTimeCalc > fourHoursInSeconds) {
		// Expire last emoji code

		lastEmojiCode = null
		localStorage.removeItem('lastEmojiCode')
		localStorage.removeItem('lastEmojiCodeTime')
	}
} else {
	lastEmojiCode = null
}

export default {
	clientId: 'chrhansen',

	timerTimeTotal: null,
	timerTimeRemaining: null,

	popupText: null,
	popupHeadline: null,

	showBigQrCode: false,
	showHelp: false,
	playerType: PlayerType.Teacher,

	teacherPassword: null,
	teacherSkipIntro: false,

	backgroundAccent: 'primary',

	breadcrumbTo: null,
	gameClientType: null, // GameClientType.Offline,
	gameType: null,

	currentTheme: null,
	currentCase: null,
	currentPage: null,

	session: null, // Used to store student votes for one case
	sessionId: null,

	lastEmojiCode: lastEmojiCode,

	currentGamePhase: null,

	currentPageIndex: -1,
	currentPagePhase: PagePhase.Normal,
	pageHistory: [],

	gameClientCloseRedirectUrl: '/',

	errorMessage: null,
	errorIcon: null,
	navigationGuardEnabled: false,

	language: null,

	appVersion: process.env.VUE_APP_VERSION,

	showFooter: true,

	autoThemeSelection: null,
	autoCaseSelection: null,
	autoRoomCode: null,

	features: AppConfig.features,

	showHeaderCaseName: true,

	admin: {
		pages: [],
	},
}

export default {
    mainMenu: {
        join: 'Start',
        create: 'Start',
        admin: 'Admin',
        language: 'Sprog',
    },
    login: {
        username: 'Brugernavn',
        password: 'Password',
        login: 'Login',
        failed: 'Login mislykkedes',
        logout: 'Log ud',
    },
    intro: {
        cultureHeadline: 'Vores Chr. Hansen-kultur',
        cultureParagraph: '\'En f\u00e6lles forst\u00e5else af vores kultur g\u00f8r os i stand til at tale med EN stemme og til at g\u00e5 fremtiden i m\u00f8de, mens vi forbliver tro mod de v\u00e6rdier, der har bragt os hertil.\' Mauricio Graber, CEO',

        whyUseHeadline: 'Hvorfor har vi dette dialogv\u00e6rkt\u00f8j om vores kultur?',
        whyUseParagraph1: 'Som virksomhed vokser vi hver eneste dag, og vi oplever l\u00f8bende nye muligheder og udfordringer. Derfor det er yderst vigtigt, at vi indg\u00e5r i dialoger omkring, hvordan vores Chr. Hansen-kultur kan indg\u00e5 i vores dagligdag, og hvordan denne kan hj\u00e6lpe os med at tr\u00e6ffe de rigtige beslutninger.',
        whyUseParagraph2: 'Form\u00e5let med dialogv\u00e6rkt\u00f8jet er at facilitere denne dialog p\u00e5 en engagerende m\u00e5de.',

        whenToUse: '# Hvorn\u00e5r bruges dialogv\u00e6rkt\u00f8jet?\n' +
            '\n' +
            'Du kan bruge v\u00e6rkt\u00f8jet ved f\u00f8lgende type m\u00f8der:\n' +
            '\n' +
            '1. Teamudvikling\n' +
            '2. Onboarding\n' +
            '3. Town halls (kort eller lang udgave)\n' +
            '\n' +
            'Varigheden af de enkelte dialogm\u00f8der vil variere afh\u00e6ngigt af antallet af deltagere og dybden af diskussionerne. Facilitatoren st\u00e5r for at guide deltagerne og s\u00e6tte scenen for hvert enkelt m\u00f8de.\n' +
            '\n' +
            'Klik p\u00e5 **N\u00e6ste** for at v\u00e6lge, hvilken type dialogm\u00f8de, du skal afholde.',

        title: 'Velkommen til\n' +
            'Chr. Hansens\n' +
            'kultur-dialogv\u00e6rkt\u00f8j',
        description: 'intro.description',
        instruction: 'Velkommen til Teamudviklingsm\u00f8det. N\u00e5r I er logget ind og klar, klik da p\u00e5 \'N\u00e6ste\' for at forts\u00e6tte.',
    },
    room: {
        connecting: 'Forbinder',
        loading: 'Indl\u00e6ser',
        roomCode: 'Rumkode',
        participate: 'Deltag',
        enterDescription: 'Indtast koden fra facilitatorens sk\u00e6rm.',
        enter: 'Enter',
        description: 'Scan QR-koden, eller f\u00f8lg linket.',
        join: 'Deltag',
        url: 'Url',
        codeError: 'Forkert rumkode',
        serverError: 'Ingen forbindelse til server',
        connectionError: 'Kunne ikke forbinde',
        lobby: 'Lobby',
        waiting: 'Venter',
        pageType: 'Spil mode',
        unset: 'Unset',
        dilemma: 'Stem & Diskuter',
        count: 'Deltagere inde',
        areReady: 'Klar',
    },
    master: {
        selectTheme: 'V\u00e6lg et tema',
    },
    game: {
        answers: 'Svar',
        chooseAnswer: 'V\u00e6lg et svar',
        showAnswers: 'Resultater',
        sendMessage: 'Send besked',
        sendMoment: 'Send \u00f8jeblik',
        sendDilemma: 'Send dilemma',
        sendAnswer: 'Send svar',
        thankYouParticipation: 'Tak for din deltagelse',
        thankYouVote: 'Tak for din stemme',
        studentWaiting: 'Kig p\u00e5 facilitatorens sk\u00e6rm',
        stayTuned: 'Vent venligst!',
        timerSeconds: 'Sek',
        ready: 'Klar',
        waitingForUserInput: 'Venter p\u00e5 bidrag fra deltagere...',
        waitingForInput: 'Venter p\u00e5 bidrag...',
    },
    instructions: {
        title: 'Instruktion',
        moments: 'instructions.moments',
        dilemmas: 'instructions.dilemmas',
        weightBoard: 'instructions.weightBoard',
        weightBoard2D: 'Klik p\u00e5 br\u00e6ttet for at placere din brik',
    },
    misc: {
        back: 'Tilbage',
        cancel: 'Annuller',
        quit: 'Afslut',
        start: 'Start',
        startAgainMoment: 'V\u00e6lg et nyt \u00f8jeblik',
        startAgainDilemma: 'V\u00e6lg et nyt dilemma',
        next: 'N\u00e6ste',
        finish: 'Afslut',
        continue: 'Forts\u00e6t',
        showResult: 'Vis resultat',
        select: 'V\u00e6lg',
        fullScreen: 'Fuld sk\u00e6rm',
        writeMomentPlaceholder: 'Klik p\u00e5 boksen for at skrive dit \u00f8jeblik...',
        writeDilemmaPlaceholder: 'Klik p\u00e5 boksen for at skrive dit dilemma...',
    },
}
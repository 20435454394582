import extend from 'extend'

import admin from './admin'
import auto from './auto'
import themes from './themes'
import cases from './cases'
import misc from './misc'
import errors from './errors'
import pages from './pages'

export default extend(
	admin,
	auto,
	themes,
	cases,
	misc,
	errors,
	pages,
)

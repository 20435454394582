export default {
    mainMenu: {
        join: 'Inicio',
        create: 'Inicio',
        admin: 'Administraci\u00f3n',
        language: 'Idioma',
    },
    login: {
        username: 'Nombre de usuario',
        password: 'Contrase\u00f1a',
        login: 'Iniciar sesi\u00f3n',
        failed: 'Fall\u00f3 el inicio de sesi\u00f3n',
        logout: 'Cerrar sesi\u00f3n',
    },
    intro: {
        cultureHeadline: 'Nuestra cultura Chr. Hansen',
        cultureParagraph: '\'Una visi\u00f3n y una descripci\u00f3n compartida de nuestra cultura nos permite hablar con UNA sola voz y avanzar hacia el futuro, permaneciendo fieles a los\n' +
            'valores que nos han llevado hasta aqu\u00ed\'. \u2013 Mauricio Graber, CEO',

        whyUseHeadline: '\u00bfPor qu\u00e9 utilizar este kit de herramientas de di\u00e1logo sobre la cultura?',
        whyUseParagraph1: 'A medida que crecemos como empresa y nos enfrentamos a nuevas oportunidades y retos cada d\u00eda, es m\u00e1s importante que nunca que entablemos di\u00e1logos sobre c\u00f3mo nuestra cultura Chr. Hansen cobra vida en nuestro trabajo diario y c\u00f3mo puede guiarnos para tomar las decisiones correctas.',
        whyUseParagraph2: 'El prop\u00f3sito de esta herramienta de di\u00e1logo sobre la cultura es facilitar ese di\u00e1logo de una manera atractiva.',

        whenToUse: '# \u00bfCu\u00e1ndo se debe utilizar la herramienta de di\u00e1logo Chr. Hansen?\n' +
            '\n' +
            'Puede utilizar la herramienta en estos tres escenarios:\n' +
            '\n' +
            '1. Desarrollo del equipo\n' +
            '2. Incorporaci\u00f3n de personal\n' +
            '3. Foros abiertos (versi\u00f3n larga o corta)\n' +
            '\n' +
            'La duraci\u00f3n de cada sesi\u00f3n variar\u00e1 en funci\u00f3n del n\u00famero de participantes y de la profundidad de los debates. El papel del facilitador es guiar a los participantes y establecer el escenario de la sesi\u00f3n.\n' +
            '\n' +
            'Haga clic en **Siguiente** para seleccionar el escenario de la sesi\u00f3n.',

        title: 'Bienvenidos al\n' +
            'Kit de Herramientas de Di\u00e1logo\n' +
            'de Chr. Hansen',
        description: 'intro.description',
        instruction: 'Bienvenidos a la sesi\u00f3n de Di\u00e1logo en Equipo. Cuando hayan iniciado la sesi\u00f3n y est\u00e9n listos, hagan clic en \'siguiente\' para continuar.',
    },
    room: {
        connecting: 'Conectando',
        loading: 'Cargando',
        roomCode: 'C\u00f3digo de la sala',
        participate: 'Participar',
        enterDescription: 'Ingrese el c\u00f3digo de la pantalla del facilitador',
        enter: 'Ingresar',
        description: 'Escanee el c\u00f3digo QR o siga el enlace.<br> Luego escriba el siguiente c\u00f3digo:',
        join: 'Unirse',
        url: 'Url',
        codeError: 'C\u00f3digo de sala incorrecto',
        serverError: 'No hay conexi\u00f3n con el servidor',
        connectionError: 'No se pudo conectar',
        lobby: 'Recepci\u00f3n',
        waiting: 'En espera',
        pageType: 'Modo de juego',
        unset: 'No configurado',
        dilemma: 'Votar y debatir',
        count: 'Jugadores que han ingresado',
        areReady: 'Listos',
    },
    master: {
        selectTheme: 'Seleccione un tema',
    },
    game: {
        answers: 'Respuestas',
        chooseAnswer: 'Seleccione una respuesta',
        showAnswers: 'Resultados',
        sendMessage: 'Enviar mensaje',
        sendMoment: 'Enviar Momento',
        sendDilemma: 'Enviar Dilema',
        sendAnswer: 'Enviar respuesta',
        thankYouParticipation: 'Gracias por participar',
        thankYouVote: 'Gracias por su voto',
        studentWaiting: 'Mire la pantalla de facilitador',
        stayTuned: '\u00a1Mant\u00e9ngase conectado!',
        timerSeconds: 'Sec',
        ready: 'Listos',
        waitingForUserInput: 'Esperando comentarios del participante',
        waitingForInput: 'Esperando comentarios',
    },
    instructions: {
        title: 'Instrucci\u00f3n',
        moments: 'instructions.moments',
        dilemmas: 'instructions.dilemmas',
        weightBoard: 'instructions.weightBoard',
        weightBoard2D: 'Haga clic en el tablero para colocar su ficha',
    },
    misc: {
        back: 'Atr\u00e1s',
        cancel: 'Cancelar',
        quit: 'Abandonar',
        start: 'Iniciar',
        startAgainMoment: 'Seleccionar nuevo Momento',
        startAgainDilemma: 'Seleccionar nuevo Dilema',
        next: 'Siguiente',
        finish: 'Finalizar',
        continue: 'Continuar',
        showResult: 'Mostrar resultado',
        select: 'Seleccionar',
        fullScreen: 'Pantalla completa',
        writeMomentPlaceholder: 'Haga clic en el casillero para escribir su momento...',
        writeDilemmaPlaceholder: 'Haga clic en el casillero para escribir su dilema.',
    },
}
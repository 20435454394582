import assets from './asset-list'
import * as RegexYouTube from 'youtube-regex'
import * as RegexVimeo from 'vimeo-regex'

export default class MediaManager {
    static setup (baseUrl) {
        this.uploadBaseUrl = baseUrl + '/uploads'
    }

    static getMediaUrl (imagePath) {
        console.log('getMediaUrl', imagePath)
        return `${this.uploadBaseUrl}${imagePath}`
    }

    static getMediaAlt (imagePath) {
        console.log('getMediaAlt', imagePath)
        let alt = imagePath

        // Remove everything before the lase slash (eg. /my/picture/here.png =
        // here.png)
        alt = alt.replace(/.*\//g, '')

        // Remove extension
        alt = alt.replace(/\.(png|jpg|gif)$/g, '')

        // Remove size
        alt = alt.replace(/\-\d+x\d+$/g, '')

        let altSplit = alt.split(/\s*[\-]+\s*/g)

        for (let i = 0, len = altSplit.length; i < len; i++) {
            altSplit[i] = altSplit[i].substr(0, 1).toUpperCase() +
                altSplit[i].substr(1)
        }

        return altSplit.join(' ')
    }

    static emojiToUnicode (emoji) {
        if (emoji.length < 4) {
            return emoji.codePointAt(0).toString(16)
        }

        return emoji.codePointAt(0).toString(16) + '-' +
            emoji.codePointAt(2).toString(16)
    }

    static getAsset (asset) {
        console.log('getAsset', asset)
        if (!assets.hasOwnProperty(asset)) {
            console.error(`Could not find asset by name "${asset}"`)
            return null
        }

        return assets[asset]
    }

    static urlIsVimeo (url) {
        let regexVimeo = RegexVimeo()

        return regexVimeo.test(url)
    }

    static urlIsYoutube (url) {
        let regexYoutube = RegExp(RegexYouTube())

        return regexYoutube.test(url)
    }
}

export default {
    'misc/connection-error.png': require('@img/misc/connection-error.png'),
    'misc/counter.png': require('@img/misc/counter.png'),
    'misc/error.png': require('@img/misc/error.png'),
    'misc/fullscreen-contract.png': require(
        '@img/misc/fullscreen-contract.png'),
    'misc/fullscreen-expand.png': require('@img/misc/fullscreen-expand.png'),
    'misc/ready-hand.png': require('@img/misc/ready-hand.png'),
    'misc/ready.png': require('@img/misc/ready.png'),
    'misc/trophy.png': require('@img/misc/trophy.png'),
    'misc/winning-point.png': require('@img/misc/winning-point.png'),
    'misc/board-texture.png': require('@img/misc/board-texture.png'),
    'misc/logo.png': require('@img/misc/logo.png'),
    'misc/logo-mini.png': require('@img/misc/logo-mini.png'),
    'misc/logo-small.png': require('@img/misc/logo-small.png'),
    'misc/shadow-3d-board.png': require('@img/misc/shadow-3d-board.png'),
    'misc/checked.png': require('@img/misc/checked.png'),
    'misc/unchecked.png': require('@img/misc/unchecked.png'),
    'misc/token.png': require('@img/misc/token.png'),
}
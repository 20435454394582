export default {
    mainMenu: {
        join: 'Commencer',
        create: 'Commencer',
        admin: 'Admin',
        language: 'Langue',
    },
    login: {
        username: 'Nom d\'utilisateur',
        password: 'Mot de passe',
        login: 'Connexion',
        failed: '\u00c9chec de la connexion',
        logout: 'Se d\u00e9connecter',
    },
    intro: {
        cultureHeadline: 'Notre Culture Chr. Hansen',
        cultureParagraph: '\'Une compr\u00e9hension et articulation partag\u00e9es de notre culture nous permettront de parler d\'UNE seule voix pour  nous permettre d\'aller de l\'avant, tout en restant fid\u00e8les aux valeurs qui nous ont amen\u00e9s ici.\' \u2013 Mauricio Graber, PDG',

        whyUseHeadline: 'Pourquoi cette bo\u00eete \u00e0 outils de dialogue sur la culture ?',
        whyUseParagraph1: 'Au fur et \u00e0 mesure que notre entreprise se d\u00e9veloppe, nous rencontrons constamment de nouveaux d\u00e9fis et opportunit\u00e9s. ll est plus important que jamais que nous nous engagions dans des dialogues sur la fa\u00e7on dont notre culture Chr.Hansen prend vie dans notre travail au quotidien et comment elle peut nous guider afin de prendre les bonnes d\u00e9cisions.',
        whyUseParagraph2: 'Le but de la bo\u00eete \u00e0 outil de dialogue culturel est de faciliter ce dialogue de mani\u00e8re engageante.',

        whenToUse: '# Quand utiliser la bo\u00eete \u00e0 outils de dialogue Chr.Hansen ?\n' +
            '\n' +
            'Vous pouvez utiliser l\'outil dans les trois configurations suivantes\u00a0:\n' +
            '\n' +
            '1. R\u00e9union d\'\u00e9quipe\n' +
            '2. L\'accueil et la formation de nouveaux employ\u00e9s\n' +
            '3. R\u00e9union inter-\u00e9quipes (version courte ou longue)\n' +
            '\n' +
            'La dur\u00e9e de chaque session variera en fonction du nombre de participants et de la profondeur des discussions. C\'est le r\u00f4le de  l\'animateur de guider les participants et d\u2019expliquer le cadre et fonctionnement de la session.\n' +
            '\n' +
            'Cliquez sur **Suivant** pour s\u00e9lectionner la configuration de la session.',

        title: 'Bienvenue dans notre\n' +
            'dialogue autour de la\n' +
            'Culture Chr. Hansen',
        description: 'intro.description',
        instruction: 'Bienvenue \u00e0 la session de Dialogue d\'\u00c9quipe. Lorsque vous \u00eates tous connect\u00e9s et pr\u00eats, veuillez cliquer sur \u00ab\u00a0suivant\u00a0\u00bb pour continuer.',
    },
    room: {
        connecting: 'Connection',
        loading: 'Chargement',
        roomCode: 'Code du salon',
        participate: 'Participer',
        enterDescription: 'Saisir le code depuis l\'\u00e9cran de l\'animateur',
        enter: 'Saisir',
        description: 'Scannez le QR code ou suivez le lien.Entrez ensuite le code suivant\u00a0:',
        join: 'Rejoindre',
        url: 'URL',
        codeError: 'Code salon incorrect',
        serverError: 'Pas de connexion au serveur',
        connectionError: 'Impossible de se connecter',
        lobby: 'Lobby',
        waiting: 'En attente',
        pageType: 'Mode jeu',
        unset: 'Non d\u00e9fini',
        dilemma: 'Votez et discutez',
        count: 'Joueurs entr\u00e9s',
        areReady: 'Pr\u00eat',
    },
    master: {
        selectTheme: 'S\u00e9lectionnez un th\u00e8me',
    },
    game: {
        answers: 'R\u00e9ponses',
        chooseAnswer: 'S\u00e9lectionnez une r\u00e9ponse',
        showAnswers: 'R\u00e9sultats',
        sendMessage: 'Envoyer le message',
        sendMoment: 'Envoyer l\'instant',
        sendDilemma: 'Envoyer le dilemme',
        sendAnswer: 'Envoyer la r\u00e9ponse',
        thankYouParticipation: 'Merci de votre participation',
        thankYouVote: 'Merci pour votre vote',
        studentWaiting: 'Regardez l\'\u00e9cran de l\'animateur',
        stayTuned: 'Patientez',
        timerSeconds: 'Seconde',
        ready: 'Pr\u00eat',
        waitingForUserInput: 'En attente de la saisie du participant...',
        waitingForInput: 'En attente de saisie...',
    },
    instructions: {
        title: 'Instruction',
        moments: 'instructions.moments',
        dilemmas: 'instructions.dilemmas',
        weightBoard: 'instructions.weightBoard',
        weightBoard2D: 'Cliquez sur le plateau pour placer votre jeton',
    },
    misc: {
        back: 'Retour',
        cancel: 'Annuler',
        quit: 'Quitter',
        start: 'Commencer',
        startAgainMoment: 'S\u00e9lectionnez un nouveau moment',
        startAgainDilemma: 'S\u00e9lectionnez un nouveau dilemme',
        next: 'Suivant',
        finish: 'Finir',
        continue: 'Continuer',
        showResult: 'Afficher le r\u00e9sultat',
        select: 'S\u00e9lectionner',
        fullScreen: 'Plein \u00e9cran',
        writeMomentPlaceholder: 'Cliquez sur la case pour \u00e9crire votre moment.',
        writeDilemmaPlaceholder: 'Cliquez sur la case pour \u00e9crire votre dilemme.',
    },
}
export default {
    mainMenu: {
        join: 'mainMenu.join',
        create: 'mainMenu.create',
        admin: 'mainMenu.admin',
        language: 'mainMenu.language',
    },
    login: {
        username: 'login.username',
        password: 'login.password',
        login: 'login.login',
        failed: 'login.failed',
        logout: 'login.logout',
    },
    intro: {
        cultureHeadline: 'intro.cultureHeadline',
        cultureParagraph: 'intro.cultureParagraph',

        whyUseHeadline: 'intro.whyUseHeadline',
        whyUseParagraph1: 'intro.whyUseParagraph1',
        whyUseParagraph2: 'intro.whyUseParagraph2',

        whenToUse: 'intro.whenToUse',

        title: 'intro.title',
        description: 'intro.description',
        instruction: 'intro.instruction',
    },
    room: {
        connecting: 'room.connecting',
        loading: 'room.loading',
        roomCode: 'room.roomCode',
        participate: 'room.participate',
        enterDescription: 'room.enterDescription',
        enter: 'room.enter',
        description: 'room.description',
        join: 'room.join',
        url: 'room.url',
        codeError: 'room.codeError',
        serverError: 'room.serverError',
        connectionError: 'room.connectionError',
        lobby: 'room.lobby',
        waiting: 'room.waiting',
        pageType: 'room.pageType',
        unset: 'room.unset',
        dilemma: 'room.dilemma',
        count: 'room.count',
        areReady: 'room.areReady',
    },
    master: {
        selectTheme: 'master.selectTheme',
    },
    game: {
        answers: 'game.answers',
        chooseAnswer: 'game.chooseAnswer',
        showAnswers: 'game.showAnswers',
        sendMessage: 'game.sendMessage',
        sendMoment: 'game.sendMoment',
        sendDilemma: 'game.sendDilemma',
        sendAnswer: 'game.sendAnswer',
        thankYouParticipation: 'game.thankYouParticipation',
        thankYouVote: 'game.thankYouVote',
        studentWaiting: 'game.studentWaiting',
        stayTuned: 'game.stayTuned',
        timerSeconds: 'game.timerSeconds',
        ready: 'game.ready',
        waitingForUserInput: 'game.waitingForUserInput',
        waitingForInput: 'game.waitingForInput',
    },
    instructions: {
        title: 'instructions.title',
        moments: 'instructions.moments',
        dilemmas: 'instructions.dilemmas',
        weightBoard: 'instructions.weightBoard',
        weightBoard2D: 'instructions.weightBoard2D',
    },
    misc: {
        back: 'misc.back',
        cancel: 'misc.cancel',
        quit: 'misc.quit',
        start: 'misc.start',
        startAgainMoment: 'misc.startAgainMoment',
        startAgainDilemma: 'misc.startAgainDilemma',
        next: 'misc.next',
        finish: 'misc.finish',
        continue: 'misc.continue',
        showResult: 'misc.showResult',
        select: 'misc.select',
        fullScreen: 'misc.fullScreen',
        writeMomentPlaceholder: 'misc.writeMomentPlaceholder',
        writeDilemmaPlaceholder: 'misc.writeDilemmaPlaceholder',
    },
}

export default {
    mainMenu: {
        join: 'Start',
        create: 'Start',
        admin: 'Admin',
        language: 'Language',
    },
    login: {
        username: 'Username',
        password: 'Password',
        login: 'Login',
        failed: 'Login failed',
        logout: 'Log Out',
    },
    intro: {
        cultureHeadline: 'Our Chr. Hansen Culture',
        cultureParagraph: '\'A shared understanding and articulation of our culture will enable us\n' +
            'to speak with ONE voice, and enable us to move forward to the future, while staying true to the values that got us here.\u201d \u2013 Mauricio Graber, CEO',

        whyUseHeadline: 'Why this dialogue tool on culture?',
        whyUseParagraph1: 'As we grow as a company and face new opportunities and challenges every day, it is more important than ever that we engage in dialogues on how our Chr. Hansen culture comes to life in our daily work and how it can guide us in making the right decisions.',
        whyUseParagraph2: 'The purpose with this culture dialogue tool is to facilitate this dialogue in an engaging way.',

        whenToUse: '# When to use the Chr. Hansen dialogue tool?\n' +
            '\n' +
            'You can use the tool in the following three settings:\n' +
            '\n' +
            '1. Team development\n' +
            '2. Onboarding\n' +
            '3. Town halls (short or long version)\n' +
            '\n' +
            'The duration of each session will vary depending on the number of participants and the depth of the discussions. It is the facilitator\u2019s role to guide the participants and set the scene for the session.\n' +
            '\n' +
            'Click **Next** to select the setting for the session.',

        title: 'Welcome to our\n' +
            'Chr. Hansen Culture\n' +
            'Dialogue Tool',
        description: 'intro.description',
        instruction: 'Welcome to the Team Dialogue session. When you are are all logged in and ready, please click \'next\' to continue.',
    },
    room: {
        connecting: 'Connecting',
        loading: 'Loading',
        roomCode: 'Room code',
        participate: 'Participate',
        enterDescription: 'Enter the code from the facilitator screen',
        enter: 'Enter',
        description: 'Scan the QR-code or follow the link.<br>Then enter the following code:',
        join: 'Join',
        url: 'Url',
        codeError: 'Wrong room code',
        serverError: 'No connection to server',
        connectionError: 'Could not connect',
        lobby: 'Lobby',
        waiting: 'Waiting',
        pageType: 'Game Mode',
        unset: 'Unset',
        dilemma: 'Vote & Discuss',
        count: 'Players entered',
        areReady: 'Ready',
    },
    master: {
        selectTheme: 'Select a theme',
    },
    game: {
        answers: 'Answers',
        chooseAnswer: 'Select an answer',
        showAnswers: 'Results',
        sendMessage: 'Send Message',
        sendMoment: 'Send Moment',
        sendDilemma: 'Send Dilemma',
        sendAnswer: 'Send answer',
        thankYouParticipation: 'Thank you for your participation',
        thankYouVote: 'Thank you for your vote',
        studentWaiting: 'Look at the facilitator\'s screen',
        stayTuned: 'Stay tuned!',
        timerSeconds: 'Sec',
        ready: 'Ready',
        waitingForUserInput: 'Waiting for participant input...',
        waitingForInput: 'Waiting for input...',
    },
    instructions: {
        title: 'Instruction',
        moments: 'instructions.moments',
        dilemmas: 'instructions.dilemmas',
        weightBoard: 'instructions.weightBoard',
        weightBoard2D: 'Click the board to place your token',
    },
    misc: {
        back: 'Back',
        cancel: 'Cancel',
        quit: 'Quit',
        start: 'Start',
        startAgainMoment: 'Select New Moment',
        startAgainDilemma: 'Select New Dilemma',
        next: 'Next',
        finish: 'Finish',
        continue: 'Continue',
        showResult: 'Show Result',
        select: 'Select',
        fullScreen: 'Full screen',
        writeMomentPlaceholder: 'Click the box to write your moment.',
        writeDilemmaPlaceholder: 'Click the box to write your dilemma.',
    },
}
export default {
    mainMenu: {
        join: 'Start',
        create: 'Start',
        admin: 'Administrator',
        language: 'Sprache',
    },
    login: {
        username: 'Nutzername',
        password: 'Passwort',
        login: 'Anmelden',
        failed: 'Fehler beim Anmelden',
        logout: 'Ausloggen',
    },
    intro: {
        cultureHeadline: 'Unsere Chr. Hansen Kultur',
        cultureParagraph: '\'Ein gemeinsames Verst\u00e4ndnis und ein gemeinsamer Ausdruck unserer Kultur erm\u00f6glichen es uns, mit einer Stimme zu sprechen und uns f\u00fcr die Zukunft bereit zu machen, ohne unsere traditionellen Werte zu vernachl\u00e4ssigen.\' - Mauricio Graber, President und CEO',

        whyUseHeadline: 'Warum \u00fcber unsere Kultur reden?',
        whyUseParagraph1: 'Da wir als Unternehmen wachsen und uns t\u00e4glich neuen Chancen und Herausforderungen stellen, ist es wichtiger denn je, in Dialoge dar\u00fcber zu treten, wie unsere Chr. Hansen-Kultur in unserer t\u00e4glichen Arbeit lebendig wird und uns dabei helfen kann, die richtigen Entscheidungen zu treffen.',
        whyUseParagraph2: 'Der Zweck dieses Kulturdialog-Tools besteht darin, diesen Dialog auf ansprechende Weise zu erleichtern.',

        whenToUse: '# Wann verwende ich das Chr. Hansen Dialogtool?\n' +
            '\n' +
            'Sie k\u00f6nnen das Tool in den folgenden drei Einstellungen verwenden:\n' +
            '\n' +
            '1. Teamentwicklung\n' +
            '2. Onboarding\n' +
            '3. Town halls (kurze oder lange Version)\n' +
            '\n' +
            'Die Dauer der einzelnen Sitzungen h\u00e4ngt von der Anzahl der Teilnehmer und der Tiefe der Diskussionen ab. Es ist die Rolle des Moderators, die Teilnehmer zu f\u00fchren und den Rahmen f\u00fcr die Sitzung zu schaffen.\n' +
            '\n' +
            'Klicken Sie auf **N\u00e4chste**, um die Einstellung f\u00fcr die Sitzung auszuw\u00e4hlen.',

        title: 'Willkommen in unserem\n' +
            'Chr. Hansen\n' +
            'Kulturdialog-Tool',
        description: 'intro.description',
        instruction: 'Willkommen zur Teamdialog-Sitzung. Wenn Sie alle eingeloggt und bereit sind, klicken Sie bitte auf \'Weiter\', um fortzufahren.',
    },
    room: {
        connecting: 'Verbindung wird hergestellt',
        loading: 'Laden',
        roomCode: 'Session Code',
        participate: 'Teilnehmen',
        enterDescription: 'Geben Sie den Code vom Moderatorenbildschirm ein',
        enter: 'Eintreten',
        description: 'QR-Code scannen oder dem Link folgen. <br> Geben Sie dann folgenden Code ein:',
        join: 'Beitreten',
        url: 'URL',
        codeError: 'Falscher Session Code',
        serverError: 'Keine Verbindung zum Server',
        connectionError: 'Konnte keine Verbindung herstellen',
        lobby: 'Lobby',
        waiting: 'Bitte warten',
        pageType: 'Spielmodus',
        unset: 'Keine Session gew\u00e4hlt',
        dilemma: 'Abstimmen & Diskutieren',
        count: 'Spieler eingetragen',
        areReady: 'Bereit',
    },
    master: {
        selectTheme: 'W\u00e4hlen Sie ein Thema',
    },
    game: {
        answers: 'Antworten',
        chooseAnswer: 'W\u00e4hlen Sie eine Antwort aus',
        showAnswers: 'Ergebnisse',
        sendMessage: 'Nachricht senden',
        sendMoment: 'Moment senden',
        sendDilemma: 'Dilemma senden',
        sendAnswer: 'Antwort senden',
        thankYouParticipation: 'Danke f\u00fcr Ihre Teilnahme',
        thankYouVote: 'Danke f\u00fcr Ihre Stimme',
        studentWaiting: 'Schauen Sie auf den Bildschirm des Moderators',
        stayTuned: 'Bleiben Sie dran!',
        timerSeconds: 'Sek',
        ready: 'Bereit',
        waitingForUserInput: 'Warten auf Teilnehmereingabe...',
        waitingForInput: 'Warte auf Eingabe...',
    },
    instructions: {
        title: 'Anweisung',
        moments: 'instructions.moments',
        dilemmas: 'instructions.dilemmas',
        weightBoard: 'instructions.weightBoard',
        weightBoard2D: 'Klicken Sie auf das Brett, um Ihren Token zu platzieren',
    },
    misc: {
        back: 'Zur\u00fcck',
        cancel: 'Abbrechen',
        quit: 'Aufh\u00f6ren',
        start: 'Start',
        startAgainMoment: 'W\u00e4hlen Sie Neu Moment',
        startAgainDilemma: 'W\u00e4hlen Sie Neu Dilemma',
        next: 'N\u00e4chste',
        finish: 'Beenden',
        continue: 'Weiter',
        showResult: 'Zeige Ergebnis',
        select: 'Ausw\u00e4hlen',
        fullScreen: 'Vollbildschirm',
        writeMomentPlaceholder: 'Klicken Sie auf das K\u00e4stchen, um Ihren Moment zu schreiben.',
        writeDilemmaPlaceholder: 'Klicken Sie auf das K\u00e4stchen, um Ihr Dilemma zu schreiben.',
    },
}
import * as EscapeHtml from 'escape-html'
import * as EmojiRegex from 'emoji-regex'

const emojiRegex = EmojiRegex()

export class TextUtil {
	static replaceEmojisInText(text) {
		let escapedText = EscapeHtml(text)

		return escapedText.replace(emojiRegex, function (emoji) {
			return emoji
		})
	}
}

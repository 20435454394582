export function getTeacherUrl(url) {
	return '/facilitator/' + url.replace(/^\/+/, '')
}

export function getStudentUrl(url) {
	return '/participant/' + url.replace(/^\/+/, '')
}

export function getAdminUrl(url) {
	return '/admin/' + url.replace(/^\/+/, '')
}

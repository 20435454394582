/*********************************************
 * OPTION VALUES
 *********************************************/
export const SET_PLAYER_TYPE = 'SET_PLAYER_TYPE'

export const SET_BACKGROUND_ACCENT = 'SET_BACKGROUND_ACCENT'

export const SET_TIMER_TIME_TOTAL = 'SET_TIMER_TIME_TOTAL';
export const SET_TIMER_TIME_REMAINING = 'SET_TIMER_TIME_REMAINING';
export const SET_POPUP_HEADLINE = 'SET_POPUP_HEADLINE';
export const SET_POPUP_TEXT = 'SET_POPUP_TEXT';

export const SET_BREADCRUMB_TO = 'SET_BREADCRUMB_TO'
export const SET_CURRENT_THEME = 'SET_CURRENT_THEME'
export const SET_CURRENT_CASE = 'SET_CURRENT_CASE'

export const SET_TEACHER_PASSWORD = 'SET_TEACHER_PASSWORD';
export const SET_TEACHER_SKIP_INTRO = 'SET_TEACHER_SKIP_INTRO';

export const ADD_CURRENT_PAGE_INDEX = 'ADD_CURRENT_PAGE_INDEX'
export const POP_CURRENT_PAGE_INDEX = 'POP_CURRENT_PAGE_INDEX'
export const SET_CURRENT_PAGE_INDEX = 'SET_CURRENT_PAGE_INDEX'
export const SET_CURRENT_PAGE_PHASE = 'SET_CURRENT_PAGE_PHASE'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const CLEAR_PAGE_HISTORY = 'CLEAR_PAGE_HISTORY'
export const ADD_CURRENT_PAGE_CHOICE = 'ADD_CURRENT_PAGE_CHOICE'
export const SET_SHOW_BIG_QR_CODE = 'SET_SHOW_BIG_QR_CODE'
export const SET_SHOW_HELP = 'SET_SHOW_HELP';

export const SET_GAME_CLIENT_CLOSE_REDIRECT_URL = 'SET_GAME_CLIENT_CLOSE_REDIRECT_URL'
export const SET_LAST_EMOJI_CODE = 'SET_LAST_EMOJI_CODE'

export const SET_CURRENT_GAME_PHASE = 'SET_CURRENT_GAME_PHASE'

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const SET_ERROR_ICON = 'SET_ERROR_ICON'
export const SET_ERROR_BUTTON = 'SET_ERROR_BUTTON'
export const SET_ERROR_BUTTON_ACTION = 'SET_ERROR_BUTTON_ACTION'
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'
export const SET_NAVIGATION_GUARD_ENABLED = 'SET_NAVIGATION_GUARD_ENABLED'
export const SET_GAME_TYPE = 'SET_GAME_TYPE'
export const SET_GAME_CLIENT_TYPE = 'SET_GAME_CLIENT_TYPE'
export const SET_SHOW_FOOTER = 'SET_SHOW_FOOTER'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const ADD_VOTES = 'ADD_VOTES'
export const SET_SESSION_ID = 'SET_SESSION_ID'

export const SET_AUTO_THEME_SELECTION = 'SET_AUTO_THEME_SELECTION'
export const SET_AUTO_CASE_SELECTION = 'SET_AUTO_CASE_SELECTION'
export const SET_AUTO_ROOM_CODE = 'SET_AUTO_ROOM_CODE'

export const SET_FEATURES = 'SET_FEATURES'

export const SET_SHOW_HEADER_CASE_NAME = 'SET_SHOW_HEADER_CASE_NAME'

export const SET_ADMIN_PAGES = 'SET_ADMIN_PAGES'

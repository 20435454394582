import * as types from './mutation-types'

export const reset = ({commit}) => {
	console.log('Store is resetting!')

	commit(types.CLEAR_ERROR_MESSAGE)
}

export const fullReset = ({commit}) => {
	console.log('Store is fully resetting!')

	commit(types.CLEAR_ERROR_MESSAGE)
	commit(types.SET_CURRENT_CASE, null)
	commit(types.SET_CURRENT_GAME_PHASE, null)
	commit(types.SET_CURRENT_PAGE_INDEX, null)
	commit(types.SET_CURRENT_PAGE_PHASE, null)
	commit(types.SET_CURRENT_PAGE, null)
	// commit(types.SET_LAST_EMOJI_CODE, null);
	commit(types.CLEAR_PAGE_HISTORY)
	commit(types.SET_NAVIGATION_GUARD_ENABLED, false)
}

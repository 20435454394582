export function getLanguages() {
    return [
        // English
        { label: 'English', code: 'en' },

        // Russian
        //{ label: 'Russian', code: 'ru' },

        // Czech
        { label: 'Čeština', code: 'cs' },

        // Indian
        //{ label: 'Indian', code: 'hi' },

        // Mandarin
        { label: '汉语', code: 'cn' },

        // Portuguese
        { label: 'Português', code: 'pt' },

        // Danish
        { label: 'Dansk', code: 'da' },

        // German
        { label: 'Deutsch', code: 'de' },

        // French
        { label: 'Français', code: 'fr' },

        // Spanish
        { label: 'Español', code: 'es' },
    ];
}

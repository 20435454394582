import Vue from 'vue'
import * as types from '../mutation-types'

export default {
	[types.SET_TIMER_TIME_TOTAL]: (state, timerTimeTotal) => {
		Vue.set(state, 'timerTimeTotal', timerTimeTotal)
	},
	[types.SET_TIMER_TIME_REMAINING]: (state, timerTimeRemaining) => {
		Vue.set(state, 'timerTimeRemaining', timerTimeRemaining)
	},
	[types.SET_POPUP_TEXT]: (state, popupText) => {
		Vue.set(state, 'popupText', popupText)
	},
	[types.SET_POPUP_HEADLINE]: (state, popupHeadline) => {
		Vue.set(state, 'popupHeadline', popupHeadline)
	},
	[types.SET_TEACHER_PASSWORD]: (state, teacherPassword) => {
		Vue.set(state, 'teacherPassword', teacherPassword)
	},
	[types.SET_TEACHER_SKIP_INTRO]: (state, teacherSkipIntro) => {
		Vue.set(state, 'teacherSkipIntro', teacherSkipIntro)
	},
	[types.SET_SHOW_BIG_QR_CODE]: (state, showBigQrCode) => {
		Vue.set(state, 'showBigQrCode', showBigQrCode)
	},
	[types.SET_SHOW_HELP]: (state, showHelp) => {
		Vue.set(state, 'showHelp', showHelp)
	},
	[types.SET_PLAYER_TYPE]: (state, playerType) => {
		Vue.set(state, 'playerType', playerType)
	},
	[types.SET_BACKGROUND_ACCENT]: (state, accent) => {
		Vue.set(state, 'backgroundAccent', accent)
	},
	[types.SET_BREADCRUMB_TO]: (state, breadcrumbTo) => {
		Vue.set(state, 'breadcrumbTo', breadcrumbTo)
	},
	[types.SET_CURRENT_GAME_PHASE]: (state, currentGamePhase) => {
		Vue.set(state, 'currentGamePhase', currentGamePhase)
	},
	[types.SET_LAST_EMOJI_CODE]: (state, lastEmojiCode) => {
		localStorage.setItem('lastEmojiCode', lastEmojiCode)
		localStorage.setItem('lastEmojiCodeTime', (new Date()).getTime())

		Vue.set(state, 'lastEmojiCode', lastEmojiCode)
	},

	[types.SET_GAME_CLIENT_CLOSE_REDIRECT_URL]: (
		state, gameClientCloseRedirectUrl) => {
		Vue.set(state, 'gameClientCloseRedirectUrl',
			gameClientCloseRedirectUrl)
	},

	[types.SET_NAVIGATION_GUARD_ENABLED]: (state, navigationGuardEnabled) => {
		Vue.set(state, 'navigationGuardEnabled', navigationGuardEnabled)
	},

	[types.SET_GAME_TYPE]: (state, gameType) => {
		Vue.set(state, 'gameType', gameType)
	},

	[types.SET_GAME_CLIENT_TYPE]: (state, gameClientType) => {
		Vue.set(state, 'gameClientType', gameClientType)
	},

	[types.SET_SESSION_ID]: (state, sessionId) => {
		Vue.set(state, 'sessionId', sessionId)
	},

	[types.SET_LANGUAGE]: (state, language) => {
		Vue.set(state, 'language', language)
	},

	[types.SET_SHOW_FOOTER]: (state, showFooter) => {
		Vue.set(state, 'showFooter', showFooter)
	},

	[types.SET_FEATURES]: (state, features) => {
		Vue.set(state, 'features', features)
	},

	[types.SET_SHOW_HEADER_CASE_NAME]: (state, showHeaderCaseName) => {
		Vue.set(state, 'showHeaderCaseName', showHeaderCaseName)
	},
}

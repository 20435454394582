import * as types from '../mutation-types'

export default {
	[types.ADD_VOTES]: (state, votes) => {
		const pageId = state.currentCase.pages[state.currentPageIndex].id

		state.session.responses.push({
			pageId: pageId,
			votes: votes,
		})
	},
}

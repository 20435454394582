import Vue from 'vue'
import Vuex from 'vuex'

import initState from './state'
import getters from './getters'
import mutations from './mutations'
import * as actions from './actions'

Vue.use(Vuex)

/**
 * TODO: split this into vuex modules
 * https://vuex.vuejs.org/en/modules.html
 **/

const store = new Vuex.Store({
	state: initState,
	getters: getters,
	mutations: mutations,
	actions: actions,
})

export default store

// Router components
import Vue from 'vue'
import VueRouter from 'vue-router'
// Generic components for pages
import IndexPage from '@/components/index.vue'
import StudentPage from '@/components/_flow-student/index.vue'
import TeacherPage from '@/components/_flow-teacher/index.vue'
import AdminPage from '@/components/_flow-admin/index.vue'
import SessionsPage from '@/components/sessions/index.vue'
// Generic pages
import NotFoundPage from '@/components/not-found.vue'
// Dev pages
import PageTester from '@/components/dev/page-tester'
import MediaTester from '@/components/dev/media-tester'
import FontTester from '@/components/dev/font-tester'
import ComponentTester from '@/components/dev/component-tester'
import WeightMapTester from '@/components/dev/weight-map-tester'
import TimerTester from '@/components/dev/timer-tester'
import ColorTester from '@/components/dev/color-tester'
import AppConfig from '@/app-config'
import MarkdownEditorTester from '@/components/dev/markdown-editor-tester';

// Allow Vue to use router methods and inject into components
Vue.use(VueRouter)

// Define routes in the app
let routes = [
	{
		path: '/',
		name: 'IndexPage',
		component: IndexPage,
	},
	{
		path: '/participant/:page',
		name: 'StudentPage',
		component: StudentPage,
	},
	{
		path: '/facilitator/:page',
		name: 'TeacherPage',
		component: TeacherPage,
	},
	{
		path: '/admin/:page',
		name: 'AdminPage',
		component: AdminPage,
	},
	{
		path: '/admin/:page/:id/:action',
		name: 'AdminActionPage',
		component: AdminPage,
	},
	{
		path: '/admin/:page/add',
		name: 'AdminAddPage',
		component: AdminPage,
	},
	{
		path: '/sessions',
		name: 'Sessions',
		component: SessionsPage,
	},
	{
		path: '/page-tester',
		name: 'PageTester',
		component: PageTester,
	},
	{ // default as it didn't match any other path
		path: '*',
		name: 'NotFoundPage',
		component: NotFoundPage,
	},
]

if (AppConfig.isDev) {
	routes.unshift({
		path: '/dev/media-tester',
		name: 'MediaTesterPage',
		component: MediaTester,
	})

	routes.unshift({
		path: '/dev/markdown-editor',
		name: 'MarkdownEditorTesterPage',
		component: MarkdownEditorTester,
	})

	routes.unshift({
		path: '/dev/font-tester',
		name: 'FontTesterPage',
		component: FontTester,
	})

	routes.unshift({
		path: '/dev/component-tester',
		name: 'ComponentTesterPage',
		component: ComponentTester,
	})

	routes.unshift({
		path: '/dev/weight-map-tester',
		name: 'WeightMapTester',
		component: WeightMapTester,
	})

	routes.unshift({
		path: '/dev/timer-tester',
		name: 'TimerTester',
		component: TimerTester,
	})

	routes.unshift({
		path: '/dev/color-tester',
		name: 'ColorTester',
		component: ColorTester,
	})
}

let router = new VueRouter({
	linkActiveClass: 'is-active',
	base: '/', // window.location.href, - test if this breaks using scorm
	routes: routes,
})

/*router.beforeEach((to, from, next) => {
	if (store.getters.getNavigationGuardEnabled) {
		console.log('Navigation guard says no!')
		return next(false) // NO!
	}

	return next()
})*/

export default router

export default {
    mainMenu: {
        join: 'Iniciar',
        create: 'Iniciar',
        admin: 'Admin',
        language: 'Idioma',
    },
    login: {
        username: 'Nome do usu\u00e1rio',
        password: 'Senha',
        login: 'Login',
        failed: 'Falha no login',
        logout: 'Sair',
    },
    intro: {
        cultureHeadline: 'Nossa cultura Chr. Hansen',
        cultureParagraph: '\u201dUma compreensa\u0303o e articulac\u0327a\u0303o compartilhados de nossa cultura nos permitira\u0301\n' +
            'falar com UMA so\u0301 voz e nos permite avanc\u0327ar para o futuro, permanecendo fie\u0301is aos valores que nos trouxeram ate\u0301 aqui\' \u2013 Mauricio Graber, CEO',

        whyUseHeadline: 'Por que essa ferramenta de di\u00e1logo sobre cultura?',
        whyUseParagraph1: '\u00c0 medida que crescemos como empresa e enfrentamos novas oportunidades e desafios todos os dias, \u00e9 mais importante do que nunca nos engajarmos em di\u00e1logos sobre como nossa cultura Chr. Hansen ganha vida em nosso trabalho di\u00e1rio e como ela pode nos orientar na tomada de decis\u00f5es corretas.',
        whyUseParagraph2: 'O objetivo desta ferramenta de di\u00e1logo de cultura \u00e9 facilitar esse di\u00e1logo de uma forma envolvente.',

        whenToUse: '# Quando usar a ferramenta de di\u00e1logo Chr. Hansen?\n' +
            '\n' +
            'Voc\u00ea pode usar a ferramenta nas tr\u00eas configura\u00e7\u00f5es a seguir:\n' +
            '\n' +
            '1. Desenvolvimento da equipe\n' +
            '2. Integra\u00e7\u00e3o\n' +
            '3. Reuni\u00f5es abertas (vers\u00e3o curta + vers\u00e3o mais longa)\n' +
            '\n' +
            'A dura\u00e7\u00e3o de cada sess\u00e3o ir\u00e1 variar dependendo do n\u00famero de participantes e da profundidade das discuss\u00f5es. \u00c9 papel do facilitador guiar os participantes e preparar o contexto para a sess\u00e3o.\n' +
            '\n' +
            'Clique em **Pr\u00f3ximo** para selecionar a configura\u00e7\u00e3o da sess\u00e3o.',

        title: 'Bem-vindo \u00e0 nossa\n' +
            'ferramenta de di\u00e1logo de\n' +
            'cultura Chr. Hansen',
        description: 'intro.description',
        instruction: 'Bem-vindo \u00e0 sess\u00e3o de di\u00e1logo de equipe. Quando voc\u00ea estiver logado e preparado, por favor clique em \'pr\u00f3ximo\' para continuar.',
    },
    room: {
        connecting: 'Conectando',
        loading: 'Carregando',
        roomCode: 'C\u00f3digo da sala',
        participate: 'Participar',
        enterDescription: 'Digite o c\u00f3digo da tela do facilitador',
        enter: 'Entrar',
        description: 'Escaneie o QR code ou acesse o link. <br> Em seguida, digite o seguinte c\u00f3digo:',
        join: 'Entrar',
        url: 'Url',
        codeError: 'C\u00f3digo de sala errado',
        serverError: 'Sem conex\u00e3o com o servidor',
        connectionError: 'N\u00e3o foi poss\u00edvel conectar',
        lobby: 'Lobby',
        waiting: 'Aguardando',
        pageType: 'Modo de Jogo',
        unset: 'Desfazer',
        dilemma: 'Votar & Discutir',
        count: 'Jogadores entraram',
        areReady: 'Pronto',
    },
    master: {
        selectTheme: 'Selecione um tema',
    },
    game: {
        answers: 'Respostas',
        chooseAnswer: 'Selecione uma resposta',
        showAnswers: 'Resultados',
        sendMessage: 'Enviar Mensagem',
        sendMoment: 'Enviar Momento',
        sendDilemma: 'Enviar Dilema',
        sendAnswer: 'Enviar resposta',
        thankYouParticipation: 'Obrigado pela sua participa\u00e7\u00e3o',
        thankYouVote: 'Obrigado pelo seu voto',
        studentWaiting: 'Olhe para a tela do facilitador',
        stayTuned: 'Fique ligado!',
        timerSeconds: 'Seg',
        ready: 'Pronto',
        waitingForUserInput: 'Aguardando contribui\u00e7\u00e3o do participante ...',
        waitingForInput: 'Aguardando contribui\u00e7\u00e3o...',
    },
    instructions: {
        title: 'Instru\u00e7\u00e3o',
        moments: 'instructions.moments',
        dilemmas: 'instructions.dilemmas',
        weightBoard: 'instructions.weightBoard',
        weightBoard2D: 'Clique no tabuleiro para colocar o seu token',
    },
    misc: {
        back: 'Voltar',
        cancel: 'Cancelar',
        quit: 'Sair',
        start: 'Iniciar',
        startAgainMoment: 'Selecionar Novo Momento',
        startAgainDilemma: 'Selecionar Novo Dilema',
        next: 'Pr\u00f3ximo',
        finish: 'Terminar',
        continue: 'Continuar',
        showResult: 'Mostrar Resultado',
        select: 'Selecionar',
        fullScreen: 'Tela cheia',
        writeMomentPlaceholder: 'Clique na caixa para escrever o seu momento.',
        writeDilemmaPlaceholder: 'Clique na caixa para escrever seu dilema.',
    },
}
export default {
    mainMenu: {
        join: '\u5f00\u59cb',
        create: '\u5f00\u59cb',
        admin: '\u7ba1\u7406\u5458',
        language: '\u8bed\u8a00',
    },
    login: {
        username: '\u7528\u6237\u540d',
        password: '\u5bc6\u7801',
        login: '\u767b\u5f55',
        failed: '\u767b\u5f55\u5931\u8d25',
        logout: '\u9000\u51fa',
    },
    intro: {
        cultureHeadline: '\u79d1\u6c49\u68ee\u6587\u5316',
        cultureParagraph: '\u201c\u5bf9\u6211\u4eec\u7684\u6587\u5316\u6709\u5171\u540c\u7684\u7406\u89e3\u548c\u8868\u8fbe\uff0c\n' +
            '\u8fd9\u8ba9\u6211\u4eec\u59cb\u7ec8\u575a\u6301\u4e00\u76f4\u4ee5\u6765\u7684\u4ef7\u503c\u89c2\uff0c\u5e76\u5728\u53d1\u5c55\u8fdb\u7a0b\u4e2d\u8fbe\u6210\u7edf\u4e00\u610f\u89c1\u3002\u201d\u2014\u2014\u79d1\u6c49\u68ee\u9996\u5e2d\u6267\u884c\u5b98Mauricio Graber',

        whyUseHeadline: '\u4e3a\u4ec0\u4e48\u5bf9\u8bdd\u5de5\u5177\u8981\u4e0e\u6587\u5316\u6302\u94a9\uff1f',
        whyUseParagraph1: '\u968f\u7740\u516c\u53f8\u7684\u53d1\u5c55\uff0c\u6211\u4eec\u6bcf\u5929\u90fd\u9762\u4e34\u7740\u65b0\u7684\u673a\u9047\u548c\u6311\u6218\uff0c\u5982\u4f55\u901a\u8fc7\u5bf9\u8bdd\u5728\u65e5\u5e38\u5de5\u4f5c\u4e2d\u751f\u52a8\u7075\u6d3b\u5730\u5c55\u73b0\u79d1\u6c49\u68ee\u6587\u5316\u3001\u5e76\u4ee5\u6b64\u6307\u5bfc\u6211\u4eec\u505a\u51fa\u6b63\u786e\u7684\u51b3\u5b9a\uff0c\u8fd9\u4e00\u70b9\u975e\u5e38\u91cd\u8981\u3002',
        whyUseParagraph2: '\u6587\u5316\u5bf9\u8bdd\u5de5\u5177\u7684\u76ee\u7684\u662f\u4ee5\u4e00\u79cd\u5f15\u4eba\u5165\u80dc\u7684\u65b9\u5f0f\u6765\u5f15\u5bfc\u5bf9\u8bdd\u3002',

        whenToUse: '#\u4f55\u65f6\u4f7f\u7528\u79d1\u6c49\u68ee\u5bf9\u8bdd\u5de5\u5177\uff1f\n' +
            '\n' +
            '\u60a8\u53ef\u4ee5\u5728\u4ee5\u4e0b\u4e09\u79cd\u573a\u666f\u4e2d\u4f7f\u7528\u8be5\u5de5\u5177\uff1a\n' +
            '\n' +
            '1.\u56e2\u961f\u5f00\u53d1\n' +
            '2.\u5165\u804c\u5f15\u5bfc\n' +
            '3.\u5458\u5de5\u5927\u4f1a\uff08\u5c0f\u5bf9\u8bdd\u6216\u957f\u5bf9\u8bdd\uff09\n' +
            '\n' +
            '\u4f1a\u8bae\u65f6\u957f\u53d6\u51b3\u4e8e\u4e0e\u4f1a\u4eba\u6570\u548c\u8ba8\u8bba\u6df1\u5ea6\u3002\u5f15\u5bfc\u5458\u8d1f\u8d23\u4e3a\u4e0e\u4f1a\u8005\u63d0\u4f9b\u6307\u5bfc\u548c\u5e03\u7f6e\u4f1a\u8bae\u573a\u666f\u3002\n' +
            '\n' +
            '\u70b9\u51fb **\u4e0b\u4e00\u6b65**\u9009\u62e9\u4f1a\u8bae\u573a\u666f\u3002',

        title: '\u6b22\u8fce\u4f7f\u7528\u79d1\u6c49\u68ee\u6587\u5316\u5bf9\u8bdd\u5de5\u5177',
        description: 'intro.description',
        instruction: '\u6b22\u8fce\u53c2\u52a0\u56e2\u961f\u5bf9\u8bdd\u4f1a\u8bae\u3002\u6240\u6709\u4e0e\u4f1a\u8005\u767b\u5f55\u5e76\u51c6\u5907\u5c31\u7eea\u540e\uff0c\u70b9\u51fb\'\u4e0b\u4e00\u6b65\'\u3002',
    },
    room: {
        connecting: '\u6b63\u5728\u8fde\u63a5',
        loading: '\u6b63\u5728\u52a0\u8f7d',
        roomCode: '\u623f\u95f4\u4ee3\u7801',
        participate: '\u53c2\u4e0e',
        enterDescription: '\u8f93\u5165\u5f15\u5bfc\u5458\u5c4f\u5e55\u4e0a\u663e\u793a\u7684\u4ee3\u7801',
        enter: '\u8fdb\u5165',
        description: '\u626b\u63cf\u4e8c\u7ef4\u7801\u6216\u70b9\u51fb\u94fe\u63a5\u64cd\u4f5c\u3002<br>\u7136\u540e\u8f93\u5165\u4ee5\u4e0b\u4ee3\u7801\uff1a',
        join: '\u52a0\u5165',
        url: '\u7f51\u5740',
        codeError: '\u623f\u95f4\u4ee3\u7801\u9519\u8bef',
        serverError: '\u672a\u8fde\u63a5\u670d\u52a1\u5668',
        connectionError: '\u65e0\u6cd5\u8fde\u63a5',
        lobby: '\u5927\u5385',
        waiting: '\u7b49\u5f85',
        pageType: '\u6e38\u620f\u6a21\u5f0f',
        unset: '\u672a\u8bbe\u7f6e',
        dilemma: '\u6295\u7968\u548c\u8ba8\u8bba',
        count: '\u4e0e\u4f1a\u8005\u5df2\u8fdb\u5165',
        areReady: '\u51c6\u5907\u5c31\u7eea',
    },
    master: {
        selectTheme: '\u9009\u62e9\u4e3b\u9898',
    },
    game: {
        answers: '\u7b54\u6848',
        chooseAnswer: '\u9009\u62e9\u7b54\u6848',
        showAnswers: '\u7ed3\u679c',
        sendMessage: '\u53d1\u9001\u4fe1\u606f',
        sendMoment: '\u53d1\u9001\u7cbe\u5f69\u77ac\u95f4',
        sendDilemma: '\u53d1\u9001\u56f0\u5883',
        sendAnswer: '\u53d1\u9001\u7b54\u6848',
        thankYouParticipation: '\u611f\u8c22\u60a8\u7684\u53c2\u4e0e',
        thankYouVote: '\u611f\u8c22\u60a8\u7684\u6295\u7968',
        studentWaiting: '\u8bf7\u770b\u5f15\u5bfc\u5458\u5c4f\u5e55',
        stayTuned: '\u8bf7\u8010\u5fc3\u7b49\u5f85\uff01',
        timerSeconds: '\u7247\u523b',
        ready: '\u51c6\u5907\u5c31\u7eea',
        waitingForUserInput: '\u7b49\u5f85\u4e0e\u4f1a\u8005\u8f93\u5165\u2026\u2026',
        waitingForInput: '\u7b49\u5f85\u8f93\u5165\u2026\u2026',
    },
    instructions: {
        title: '\u4ecb\u7ecd',
        moments: 'instructions.moments',
        dilemmas: 'instructions.dilemmas',
        weightBoard: 'instructions.weightBoard',
        weightBoard2D: '\u70b9\u51fb\u6587\u5316\u6a21\u578b\u677f\u6807\u8bb0\u4f4d\u7f6e',
    },
    misc: {
        back: '\u8fd4\u56de',
        cancel: '\u53d6\u6d88',
        quit: '\u9000\u51fa',
        start: '\u5f00\u59cb',
        startAgainMoment: '\u9009\u62e9\u65b0\u7684\u7cbe\u5f69\u77ac\u95f4',
        startAgainDilemma: '\u9009\u62e9\u65b0\u56f0\u5883',
        next: '\u4e0b\u4e00\u6b65',
        finish: '\u5b8c\u6210',
        continue: '\u7ee7\u7eed',
        showResult: '\u663e\u793a\u7ed3\u679c',
        select: '\u9009\u62e9',
        fullScreen: '\u5168\u5c4f',
        writeMomentPlaceholder: '\u70b9\u51fb\u5bf9\u8bdd\u6846\u8bb0\u5f55\u60a8\u7684\u7cbe\u5f69\u77ac\u95f4\u3002',
        writeDilemmaPlaceholder: '\u70b9\u51fb\u5bf9\u8bdd\u6846\u8bb0\u5f55\u60a8\u7ecf\u5386\u8fc7\u7684\u56f0\u5883\u3002',
    },
}
// case

export default {
	getAutoThemeSelection(state) {
		return state.autoThemeSelection
	},

	getAutoCaseSelection(state) {
		return state.autoCaseSelection
	},

	getAutoRoomCode(state) {
		return state.autoRoomCode
	},
}
